import React, { useState, useEffect } from "react";
import Options from "../../SubComponents/Options/Options";
import "./addDetails.css";
import DirectAnswer from "../../SubComponents/DirectAnswer/DirectAnswer";
import OptionsRight from "../../SubComponents/OptionsRight/OptionsRight";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Dropdown from "../../SubComponents/Dropdown/Dropdown";
import configuration from "../../config/Config";
import s3 from "../../config/DigitalOcean";
import Axios from "axios";
import { BASE_URL } from "../../config/Api";
import Matching from "../../SubComponents/Matching/Matching";
import MAQ from "../../SubComponents/MAQ/MAQ";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { BASE_URL_MIGRATE } from "../../config/Api";
import {
  createQuestion,
  editQuestion,
  fetchParticularQuestion,
  curateQuestion,
  rejectQuestion,
} from "../../../api/questions";
import {
  questionMetadata,
  updateQueMetadata,
  getQueMetadata,
} from "../../../api/user";
import {
  fetchAccessLevel,
  fetchResponseType,
  fetchEvalType,
  fetchQuestionType,
  fetchAnsType,
} from "../../../api/qbMasterData";

import { boardList } from "../../../api/boards";
import {
  fetchCourse,
  fetchSubject,
  fetchSubSubject,
  fetchSubTopic,
  fetchTopic,
} from "../../../api/institution";
import { fetchMedium, fetchMediumSub } from "../../../api/mediums";
import { fetchTaxonomy } from "../../../api/taxonomy";
import { fetchObjective } from "../../../api/objective";
import { REWRITE_URLS } from "../../config/Api";
import FileViewer from "react-file-viewer";
import { Player } from "@lottiefiles/react-lottie-player";

const difficultyLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const alphabet = ["A", "B", "C", "D", "E", "F"];
const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

var institution_id = 0;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var sub_subject_id = -1;
var topic_1_id = -1;
var topic_2_id = -1;
var taxonomy_id = -1;
var question_id = -1;
var FilePath;
var MAQArray = [];
var MCQCrtArray = [0, 0, 0, 0, 0, 0];
var MCQNrArray = [0, 0, 0, 0, 0, 0];
var metaData;
var tempData;
var tempArray = [];
var MAQTemp;
var tempAnswerJson;
const MCQNumber = 1;
const yesOrNoNumber = 2;
const zeroNumber = 3;
const MAQNumber = 4;
const matchingNumber = 5;
const oneWordNumber = 6;
const FITBNumber = 7;
const textNumber = 8;
const voiceNumber = 9;
const imageNumber = 10;
const videoNumber = 11;
const fileNumber = 12;
const numOptionValue = 6;
var curatorData;
var curatorName;
var previousPath;
var curatorId;
var questionReuseId = null;
const docFile = "docx";
let solutionFileChoose = false;

function AddDetails(props) {
  // document.addEventListener("wheel", function (event) {
  //   if (document.activeElement.type === "number") {
  //     document.activeElement.blur();
  //   }
  // });
  const [formData, setFormData] = useState({
    question_file_id: "",
    copiable_data_file_path: "",
    reference: "",
    question_type_id: -1,
    board_id: -1,
    course_id: -1,
    subject_id: -1,
    sub_subject_id: -1,
    topic_1_id: -1,
    sub_topic_1_id: -1,
    topic_2_id: -1,
    sub_topic_2_id: -1,
    difficulty_level: "",
    answer_input_type_id: -1,
    response_type_id: -1,
    evaluation_type_id: -1,
    access_level_id: -1,
    taxonomy_id: -1,
    objective_id: -1,
    max_marks: 0,
    time_required: 0,
    medium_id: -1,
    short_description: "",
    description: "",
    hint: "",
    solution_text: "",
    is_enabled: true,
    answer_json: "[]",
    solution_file: "",
    added_by_user_id: -1,
    image_path: "",
    question_reuse_id: null,
  });
  const [state, setState] = useState({
    boards: [],
    courses: [],
    subjects: [],
    mediums: [],
    subSubjects: [],
    topics: [],
    subTopics: [],
    subTopics2: [],
    questionsType: [],
    answerInput: [],
    responseType: [],
    evaluation: [],
    accessLevel: [],
    taxonomies: [],
    objectives: [],
  });

  const [matchingCount, setMatchingCount] = useState(1);
  const [backlog, setBacklog] = useState(false);
  const [viewQuestion, setViewQuestion] = useState(false);
  const [prvLink, setPrvLink] = useState("");
  const [copiableData, setCopiableData] = useState("");
  const [solutionData, setSolutionData] = useState("");
  const [responseFile, setResponseFile] = useState("");
  const [responseMatchingData, setResponseMatchingData] = useState([]);
  const [responseMCQ, setResponseMCQ] = useState([]);
  const [responseMAQData, setResponseMAQData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [copyUpload, setCopyUpload] = useState(false);
  const [copyUploadLoading, setCopyUploadLoading] = useState(false);
  const [solFileUpload, setSolFileUpload] = useState(false);
  const [solUploadLoading, setSolUploadLoading] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [resImgae, setResImage] = useState(false);
  const [resVoice, setResVoice] = useState(false);
  const [resVideo, setResVideo] = useState(false);
  const [resFile, setResFile] = useState(false);
  const [resUploadLoading, setResUploadLoading] = useState(false);
  const [errorQueSub, setErrorQueSub] = useState("Error in submitting");
  const [notFilled, setNotFilled] = useState(false);
  const [curator, setCurator] = useState(false);
  const [isCurated, setIsCurated] = useState(false);
  const [isAlreadyCurated, setIsAlreadyCurated] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [printURL, setPrintURL] = useState("");

  const history = useHistory();
  const token = window.localStorage.getItem("token");
  const userId = window.localStorage.getItem("userId");

  const Auth = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  console.log("formData, curatorId, curator", formData, curatorId, curator);
  console.log(
    `institution_id,
    board_id,
    course_id,
    subject_id,
    sub_subject_id,
    topic_1_id,
    topic_2_id,
    taxonomy_id,
    question_id`,
    institution_id,
    board_id,
    course_id,
    subject_id,
    sub_subject_id,
    topic_1_id,
    topic_2_id,
    taxonomy_id,
    question_id
  );

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      answer_json: JSON.stringify(responseMCQ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(responseMCQ)]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      answer_json: JSON.stringify(responseMAQData),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(responseMAQData)]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      answer_json: JSON.stringify(responseMatchingData),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(responseMatchingData)]);

  async function handleQueDelete() {
    var migrateQuestionId = window.localStorage.getItem("migrateQuestionId");
    console.log(migrateQuestionId);
    if (migrateQuestionId && migrateQuestionId !== null) {
      let data = {
        Questions: [
          {
            QuestionId: migrateQuestionId,
            IsSync: true,
          },
        ],
      };

      Axios.post(
        `${BASE_URL_MIGRATE}/PostQuestionMigrateBulk`,
        qs.stringify(data)
      )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            console.log("PostQuestionMigrateBulk error", err.response);
            toast.error(err.response.data.message);
          } else {
            toast.error("Server Error");
          }
        });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("test");
    if (printURL && !formData.copiable_data_file_path) {
      console.log("test1");
      toast.error("Printable file is attached but not uploaded");
    }
    if (solutionFileChoose && !formData.solution_file) {
      console.log("test2");
      toast.error("Solution file is attached but not uploaded");
    } else {
      console.log("test3");
      if (
        formData.question_type_id === "-1" ||
        formData.board_id === "-1" ||
        formData.course_id === "-1" ||
        formData.subject_id === "-1" ||
        formData.sub_subject_id === "-1" ||
        formData.topic_1_id === "-1" ||
        formData.sub_topic_1_id === "-1" ||
        formData.difficulty_level === "-1" ||
        formData.answer_input_type_id === "-1" ||
        formData.response_type_id === "-1" ||
        formData.evaluation_type_id === "-1" ||
        formData.access_level_id === "-1" ||
        formData.taxonomy_id === "-1" ||
        formData.objective_id === "-1" ||
        formData.max_marks === "" ||
        formData.time_required === "" ||
        formData.medium_id === "-1" ||
        formData.medium_id === -1 ||
        formData.short_description === "" ||
        formData.question_type_id === -1 ||
        formData.board_id === -1 ||
        formData.course_id === -1 ||
        formData.subject_id === -1 ||
        formData.sub_subject_id === -1 ||
        formData.topic_1_id === -1 ||
        formData.sub_topic_1_id === -1 ||
        formData.difficulty_level === -1 ||
        formData.answer_input_type_id === -1 ||
        formData.response_type_id === -1 ||
        formData.evaluation_type_id === -1 ||
        formData.access_level_id === -1 ||
        formData.taxonomy_id === -1 ||
        formData.objective_id === -1 ||
        formData.max_marks === 0 ||
        formData.max_marks === "" ||
        formData.time_required === 0
      ) {
        console.log("test4");
        toast.error("Please fill all the madatory data");
        setNotFilled(true);
      } else {
        console.log("test5");
        setSubmited(false);
        setSubmitError(false);
        const pref = {
          pref_key: "question_defaults",
          pref_value_json: JSON.stringify(formData),
        };
        if (backlog === true) {
          console.log("test6");
          setSubmitLoading(true);
          setStartUpLoad(true);
          setSubmitError(false);
          window.localStorage.setItem("formData", JSON.stringify(formData));
          console.log("formData", formData);
          try {
            console.log("test7");
            var responseQue = await createQuestion(formData);
            metaData = await updateQueMetadata(userId, pref);
            console.log("responseQue", responseQue);
            console.log("metaData", metaData);
            if (responseQue.data.data.o_question_id) {
              console.log("test8");
              if (curator && isCurated) {
                console.log("test9");
                question_id = responseQue.data.data.o_question_id;
                console.log("question_id", question_id);
                curatorData = {
                  is_curated: isCurated,
                  curated_by_user_id: userId,
                };
                console.log("curatorData", curatorData);
                try {
                  var curatingBacklog = await curateQuestion(
                    question_id,
                    curatorData
                  );
                  console.log("curatingBacklog", curatingBacklog);
                } catch (err) {
                  setErrorQueSub(err.response.data.message);
                  if (err.response && err.response.data) {
                    console.log("curatingBacklog", err.response.data.message);
                    toast.error(err.response.data.message);
                  } else {
                    toast.error("Server Error");
                  }
                }
              }
            }
            if (responseQue.status === 200) {
              console.log("test10");
              handleQueDelete();
              setSubmited(true);
              setSubmitLoading(false);
              setStartUpLoad(false);
              MCQCrtArray = [];
              MCQNrArray = [];
              MAQArray = [];
              setTimeout(() => history.push("/admin/backlog"), 1000);
            }
          } catch (err) {
            if (err.response && err.response.data) {
              console.log("test11");
              console.log(err.response.data.message);
              toast.error(err.response.data.message);
            } else {
              toast.error("Server Error");
            }
            setSubmitLoading(false);
            setStartUpLoad(false);
            setSubmitError(true);
          }
        }
        if (viewQuestion === true) {
          console.log("test12");
          setSubmitLoading(true);
          setStartUpLoad(true);
          window.localStorage.setItem("formData", JSON.stringify(formData));
          console.log(formData);
          console.log(questionReuseId);
          try {
            if (questionReuseId) {
              console.log("test13");
              let reuseCreate = await createQuestion(formData);
              console.log("reuseCreate", reuseCreate);
            } else {
              console.log("test14");
              var response = await editQuestion(question_id, formData);
              console.log("response", response);
            }
            metaData = await updateQueMetadata(userId, pref);
            console.log("updateQueMetadata metaData", metaData);
            if (curator && isCurated) {
              console.log("test15");
              curatorData = {
                is_curated: isCurated,
                curated_by_user_id: userId,
              };
              console.log("curatorData", curatorData);
              try {
                var curatingViewQuestions = await curateQuestion(
                  question_id,
                  curatorData
                );
                console.log("curatingViewQuestions", curatingViewQuestions);
              } catch (err) {
                setErrorQueSub(err.response.data.message);
                if (err.response && err.response.data) {
                  console.log("test16");
                  console.log(err.response.data.message);
                  toast.error(err.response.data.message);
                } else {
                  toast.error("Server Error");
                }
              }
            }
            if (metaData.status === 200) {
              setSubmited(true);
              setSubmitLoading(false);
              setStartUpLoad(false);
              MCQCrtArray = [];
              MCQNrArray = [];
              MAQArray = [];
              window.localStorage.setItem("previousPath", "addDetails");
              setTimeout(() => history.push("/admin/view-questions"), 2000);
            }
          } catch (err) {
            setErrorQueSub(err.response.data.message);
            if (err.response && err.response.data) {
              console.log(err.response.data.message);
              toast.error(err.response.data.message);
            } else {
              toast.error("Server Error");
            }
            setSubmitLoading(false);
            setStartUpLoad(false);
            setSubmitError(true);
          }
        }
      }
    }
  }

  //function to store file choosen in copiable data file in local state  choose
  function handleChangeFile(e) {
    var selFile = e.target.files[0];
    if (selFile) {
      let urlData = URL.createObjectURL(selFile);
      setPrintURL(urlData);
      setCopiableData(selFile);
    } else {
      setPrintURL("");
      setCopiableData("");
    }
  }

  //function to store file choosen in solution file in local state
  function handleSoultionFile(e) {
    var selFile = e.target.files[0];
    if (selFile) {
      solutionFileChoose = true;
      setSolutionData(selFile);
    } else {
      solutionFileChoose = false;
      setSolutionData("");
    }
  }

  //function to add question copiable data file to form data
  function copyFileUpload(e) {
    e.preventDefault();
    if (copiableData !== undefined) {
      if (copiableData.name) {
        setCopyUploadLoading(true);
        var file = copiableData;
        var userId = window.localStorage.getItem("userId");
        var newDate = new Date();
        var date =
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear() +
          "-" +
          newDate.getHours() +
          "-" +
          newDate.getMinutes() +
          "-" +
          newDate.getSeconds();
        FilePath =
          configuration.folderName +
          "/" +
          configuration.copyFileFolder +
          "/" +
          date +
          "-" +
          userId +
          "-" +
          file.name;
        var pdfFilePath = configuration.digitalOceanSpaces + FilePath;
        console.log("pdfFilePath", pdfFilePath);
        setFormData({
          ...formData,
          copiable_data_file_path: pdfFilePath,
        });

        const params = {
          Body: file,
          Bucket: `${configuration.bucketName}`,
          Key: FilePath,
        };
        s3.putObject(params)
          .on("build", (request) => {
            request.httpRequest.headers.Host = `${
              REWRITE_URLS === "1"
                ? configuration.digitalOceanSpacesRewrite
                : configuration.digitalOceanSpaces
            }`;
            request.httpRequest.headers["Content-Length"] = file.size;
            request.httpRequest.headers["Content-Type"] = file.type;
            request.httpRequest.headers["x-amz-acl"] = "public-read";
            request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
            setCopyUploadLoading(false);
            setCopyUpload(true);
          })
          .send((err) => {
            if (err) {
              console.log("Img error", err);
              toast.error(err.response.data.message);
              setFormData({
                ...formData,
                copiable_data_file_path: "",
              });
              setCopyUploadLoading(false);
            }
          });
      }
    } else {
      toast.error("Please upload a valid file");
    }
  }

  function solutionFileUpload(e) {
    e.preventDefault();
    if (solutionData !== undefined) {
      if (solutionData.name) {
        setSolUploadLoading(true);
        var file = solutionData;
        console.log(file.name);
        var userId = window.localStorage.getItem("userId");
        var newDate = new Date();
        var date =
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear() +
          "-" +
          newDate.getHours() +
          "-" +
          newDate.getMinutes() +
          "-" +
          newDate.getSeconds();
        FilePath =
          configuration.folderName +
          "/" +
          configuration.solutionFolder +
          "/" +
          date +
          "-" +
          userId +
          "-" +
          file.name;
        var pdfFilePath = configuration.digitalOceanSpaces + FilePath;
        console.log("pdfFilePath", pdfFilePath);
        setFormData({
          ...formData,
          solution_file: pdfFilePath,
        });

        const params = {
          Body: file,
          Bucket: `${configuration.bucketName}`,
          Key: FilePath,
        };
        s3.putObject(params)
          .on("build", (request) => {
            request.httpRequest.headers.Host = `${
              REWRITE_URLS === "1"
                ? configuration.digitalOceanSpacesRewrite
                : configuration.digitalOceanSpaces
            }`;
            request.httpRequest.headers["Content-Length"] = file.size;
            request.httpRequest.headers["Content-Type"] = file.type;
            request.httpRequest.headers["x-amz-acl"] = "public-read";
            request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
            setSolUploadLoading(false);
            setSolFileUpload(true);
          })
          .send((err) => {
            if (err) {
              console.log("Img error", err);
              toast.error(err.response.data.message);
              setFormData({
                ...formData,
                solution_file: "",
              });
              setSolUploadLoading(false);
            }
          });
      }
    } else {
      toast.error("Please upload a valid file");
    }
  }

  // function to add Matcing option in Response type - Matching
  function incrementMatching(e) {
    e.preventDefault();
    if (matchingCount !== 10) {
      setMatchingCount(matchingCount + 1);
    }
  }

  // function to remove particular MAQ option in Response type - Matching
  function handleRemove(e, key, selLabel) {
    e.preventDefault();
    if (selLabel === "Matching") {
      setMatchingCount(matchingCount - 1);
      var matchingList = responseMatchingData;
      matchingList.splice(key, 1);
      matchingList = matchingList.map((obj, keyObj) => {
        return { ...obj, left_hand_option: keyObj + 1 };
      });
      setResponseMatchingData(matchingList);
      console.log(matchingList);
    }
  }

  // function to save particular MAQ option data in Response type - MAQ
  function handleChangeOption(e, selLabel, keyNum) {
    if (selLabel === "MAQ") {
      var MAQOptionData = e.target.checked;
      if (MAQOptionData === true) {
        MAQTemp = responseMAQData;
        MAQTemp.push({ option_text: keyNum, is_correct_option: MAQOptionData });
        setResponseMAQData([...MAQTemp]);
        console.log(responseMAQData);
      }
      if (MAQOptionData === false) {
        MAQTemp = responseMAQData;
        MAQTemp = MAQTemp.filter((obj) => {
          return obj.option_text !== keyNum;
        });
        setResponseMAQData([...MAQTemp]);
        console.log("responseMAQData MAQOptionData === false", responseMAQData);
      }
    }
    if (selLabel === "Matching") {
      var matchingOptionData = e.target.value;
      var matchingTemp = responseMatchingData;
      matchingTemp[keyNum - 1] = {
        left_hand_option: keyNum,
        right_hand_option: matchingOptionData,
      };
      setResponseMatchingData([...matchingTemp]);
      console.log(
        "responseMatchingData selLabel === Matching",
        responseMatchingData
      );
    }
  }

  //function to hnadle response type 0-10, FITB, Text, one word answer
  function handleChangeResponse(e) {
    var res = e.target.value;
    setFormData({
      ...formData,
      answer_json: {
        // ...formData.answer_json,
        correct_answer: res,
      },
    });
  }

  // To save selected file in response to the formData
  function handleResponceFile(e, name) {
    var file = e.target.files[0];
    setResponseFile(file);
  }

  function responseUploadFile(e, type) {
    e.preventDefault();
    if (responseFile === "") {
      toast.error("Choose the File");
    }
    if (responseFile !== "") {
      setResUploadLoading(true);
      let file = responseFile;
      var userId = window.localStorage.getItem("userId");
      var newDate = new Date();
      var date =
        newDate.getDate() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getFullYear() +
        "-" +
        newDate.getHours() +
        "-" +
        newDate.getMinutes() +
        "-" +
        newDate.getSeconds();
      FilePath =
        configuration.folderName +
        "/" +
        configuration.responseFolder +
        "/" +
        date +
        "-" +
        userId +
        "-" +
        file.name;
      var pdfFilePath = configuration.digitalOceanSpaces + FilePath;
      console.log("pdfFilePath", pdfFilePath);
      if (type !== "Files") {
        setFormData({
          ...formData,
          answer_json: JSON.stringify({ answer_file_path: pdfFilePath }),
        });
      }
      if (type === "Files") {
        setFormData({
          ...formData,
          answer_json: JSON.stringify([{ answer_file_path: pdfFilePath }]),
        });
      }
      const params = {
        Body: file,
        Bucket: `${configuration.bucketName}`,
        Key: FilePath,
      };
      s3.putObject(params)
        .on("build", (request) => {
          request.httpRequest.headers.Host = `${
            REWRITE_URLS === "1"
              ? configuration.digitalOceanSpacesRewrite
              : configuration.digitalOceanSpaces
          }`;
          request.httpRequest.headers["Content-Length"] = file.size;
          request.httpRequest.headers["Content-Type"] = file.type;
          request.httpRequest.headers["x-amz-acl"] = "public-read";
          request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
          setResUploadLoading(false);
          if (type === "Files") {
            setResFile(true);
          }
          if (type === "Image") {
            setResImage(true);
          }
          if (type === "Voice") {
            setResVoice(true);
          }
          if (type === "Video") {
            setResVideo(true);
          }
        })
        .send((err) => {
          if (err) {
            console.log("Img error", err);
            toast.error(err.response.data.message);
            setResUploadLoading(false);
          }
        });
    }
  }

  // function to save MCQ data selected under response MCQ
  function handleChangeOptionMCQ(e, crtNear, selLabel, numberSel) {
    const valueSele = e.target.checked;
    if (valueSele === true) {
      if (crtNear === "correct") {
        tempData = responseMCQ.filter((obj) => {
          return obj.option_text !== numberSel;
        });
        tempData = [
          ...tempData,
          {
            option_text: numberSel,
            is_correct_option: true,
            is_near_correct_option: false,
          },
        ];
        setResponseMCQ([...tempData]);
        console.log("responseMCQ", responseMCQ);
      }
      if (crtNear === "nearCorrect") {
        tempData = responseMCQ.filter((obj) => {
          return obj.option_text !== numberSel;
        });
        tempData = [
          ...tempData,
          {
            option_text: numberSel,
            is_correct_option: false,
            is_near_correct_option: true,
          },
        ];
        setResponseMCQ([...tempData]);
        console.log("responseMCQ", responseMCQ);
      }
    }
    if (valueSele === false) {
      if (crtNear === "correct") {
        tempData = responseMCQ.filter((obj) => {
          return obj.option_text !== numberSel;
        });
        setResponseMCQ([...tempData]);
        console.log("responseMCQ correct", responseMCQ);
      }
      if (crtNear === "nearCorrect") {
        tempData = responseMCQ.filter((obj) => {
          return obj.option_text !== numberSel;
        });
        setResponseMCQ([...tempData]);
        console.log("responseMCQ nearCorrect", responseMCQ);
      }
    }
  }

  //function to save yes or no under response yes/no
  function handleChangeOptionYesNo(trueOrFalse) {
    setFormData({
      ...formData,
      // answer_json: JSON.stringify({
      answer_json: {
        // ...formData.answer_json,
        correct_answer: trueOrFalse,
      },
    });
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    previousPath = window.localStorage.getItem("previousPath");
    const previousImageLink = window.localStorage.getItem("previousImageLink");
    const token = window.localStorage.getItem("token");
    const userId = window.localStorage.getItem("userId");
    let questionIdBacklog = window.localStorage.getItem("questionId");
    const questionIdData = window.localStorage.getItem("question_id");
    let questionFileId = window.localStorage.getItem("questionFileId");
    console.log("questionFileId", questionFileId);
    const role = window.localStorage.getItem("role");
    // reuse = window.localStorage.getItem("reuse");
    questionReuseId = window.localStorage.getItem("reuseId");

    setFormData({ ...formData, added_by_user_id: userId });
    if (role === "Curator" || role === "Admin") {
      setCurator(true);
    }

    const Auth = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const fetchFirstResults = async () => {
      console.log("first");
      try {
        const getQuestionType = await fetchQuestionType();
        const getAccessLevel = await fetchAccessLevel();
        const getResponseType = await fetchResponseType();
        const getEvaluation = await fetchEvalType();
        const getInputType = await fetchAnsType();
        const getTaxonomies = await fetchTaxonomy();
        const getMediums = await fetchMedium();

        setState((prevState) => ({
          ...prevState,
          questionsType: getQuestionType.data.data,
          answerInput: getInputType.data.data,
          responseType: getResponseType.data.data,
          evaluation: getEvaluation.data.data,
          accessLevel: getAccessLevel.data.data,
          taxonomies: getTaxonomies.data.data,
          mediums: getMediums.data.data,
        }));
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    };
    await fetchFirstResults();

    const fetchResults = async () => {
      try {
        const getQuestionType = await fetchQuestionType();
        const getAccessLevel = await fetchAccessLevel();
        const getResponseType = await fetchResponseType();
        const getEvaluation = await fetchEvalType();
        const getInputType = await fetchAnsType();
        const getTaxonomies = await fetchTaxonomy();
        const getMediums = await fetchMedium();
        const getBoard = await boardList();
        const getCourse = await fetchCourse(institution_id, board_id);
        const getSubject = await fetchSubject(
          institution_id,
          board_id,
          course_id
        );
        const getSubSubject = await fetchSubSubject(
          institution_id,
          board_id,
          course_id,
          subject_id
        );
        const getTopic = await fetchTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id
        );
        console.log("topic_1_id", topic_1_id);
        const getSubTopic = await fetchSubTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id,
          topic_1_id
        );
        const getSubTopic2 = await Axios.get(
          `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_2_id}/sub_topics`,
          Auth
        );
        const getObjectives = await fetchObjective(taxonomy_id);
        setState((prevState) => ({
          ...prevState,
          questionsType: getQuestionType.data.data,
          answerInput: getInputType.data.data,
          responseType: getResponseType.data.data,
          evaluation: getEvaluation.data.data,
          accessLevel: getAccessLevel.data.data,
          taxonomies: getTaxonomies.data.data,
          mediums: getMediums.data.data,
          boards: getBoard.data.data,
          courses: getCourse.data.data,
          subjects: getSubject.data.data,
          subSubjects: getSubSubject.data.data,
          topics: getTopic.data.data,
          subTopics: getSubTopic.data.data,
          subTopics2: getSubTopic2.data.data,
          objectives: getObjectives.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    };

    async function fetchMetaData() {
      try {
        const submitedFormData = await getQueMetadata(
          userId,
          "question_defaults"
        );
        console.log("submitedFormData", submitedFormData.data.data[0]);
        if (previousPath === "backlog") {
          if (submitedFormData.data.data.length === 0) {
            async function meta() {
              const pref = {
                pref_key: "question_defaults",
                pref_value_json: JSON.stringify(formData),
              };
              try {
                metaData = await questionMetadata(userId, pref);
                console.log(metaData);
                setStartUpLoad(false);
              } catch (err) {
                if (err.response && err.response.data) {
                  console.log(err.response.data.message);
                  toast.error(err.response.data.message);
                } else {
                  toast.error("Server Error");
                }
                setStartUpLoad(false);
              }
            }
            meta();
          }
          if (submitedFormData.data.data.length !== 0) {
            const subData = JSON.parse(
              submitedFormData.data.data[0].pref_value_json
            );
            if (subData.answer_json !== "" && subData.answer_json !== "{}") {
              console.log("subData", subData);
              console.log(
                "submitedFormData.data.data[0].pref_value_json",
                submitedFormData.data.data[0].pref_value_json
              );
              setFormData({
                ...formData,
                question_file_id: questionIdBacklog,
                copiable_data_file_path: subData.copiable_data_file_path,
                reference: subData.reference,
                question_type_id: subData.question_type_id,
                board_id: subData.board_id,
                course_id: subData.course_id,
                subject_id: subData.subject_id,
                sub_subject_id: subData.sub_subject_id,
                topic_1_id: subData.topic_1_id,
                sub_topic_1_id: subData.sub_topic_1_id,
                topic_2_id: subData.topic_2_id,
                sub_topic_2_id: subData.sub_topic_2_id,
                // difficulty_level: subData.difficulty_level,
                answer_input_type_id: subData.answer_input_type_id,
                // response_type_id: subData.response_type_id,
                evaluation_type_id: subData.evaluation_type_id,
                access_level_id: subData.access_level_id,
                taxonomy_id: subData.taxonomy_id,
                // objective_id: subData.objective_id,
                // max_marks: subData.max_marks,
                // time_required: subData.time_required,
                medium_id: subData.medium_id,
                // short_description: subData.short_description,
                // description: subData.description,
                // hint: subData.hint,
                // solution_text: subData.solution_text,
                is_enabled: subData.is_enabled,
                answer_json: subData.answer_json,
                solution_file: subData.solution_file,
                image_path: previousImageLink,
                added_by_user_id: userId,
              });
              console.log("acid");
              console.log("subData", subData);
              board_id = +subData.board_id || -1;
              course_id = +subData.course_id || -1;
              subject_id = +subData.subject_id || -1;
              sub_subject_id = +subData.sub_subject_id || -1;
              topic_1_id = +subData.topic_1_id || -1;
              topic_2_id = +subData.topic_2_id || -1;
              taxonomy_id = +subData.taxonomy_id || -1;
              console.log(
                `institution_id,
                board_id,
                course_id,
                subject_id,
                sub_subject_id,
                topic_1_id,
                topic_2_id,
                taxonomy_id`,
                institution_id,
                board_id,
                course_id,
                subject_id,
                sub_subject_id,
                topic_1_id,
                topic_2_id,
                taxonomy_id
              );
            }
            fetchResults();
          }
        }
      } catch (err) {
        console.log(err);
        if (err.response) {
          console.log(err.response);
        }
        toast.error("Error");
        setStartUpLoad(false);
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      image_path: previousImageLink,
      added_by_user_id: userId,
    }));
    async function fetchFormData() {
      try {
        var idFormData = await fetchParticularQuestion(question_id);
        console.log("idFormData.data", idFormData.data);
        console.log("idFormData.data.data[0]", idFormData.data.data[0]);
        if (
          idFormData.data.data[0] &&
          Object.keys(idFormData.data.data[0]).length !== 0
        ) {
          setFormData({
            ...formData,
            question_file_id: questionFileId,
            copiable_data_file_path:
              idFormData.data.data[0].copiable_data_file_path,
            reference: idFormData.data.data[0].reference,
            question_type_id: idFormData.data.data[0].question_type_id,
            board_id: idFormData.data.data[0].board_id,
            course_id: idFormData.data.data[0].course_id,
            subject_id: idFormData.data.data[0].subject_id,
            sub_subject_id: idFormData.data.data[0].sub_subject_id,
            topic_1_id: idFormData.data.data[0].topic_1_id,
            sub_topic_1_id: idFormData.data.data[0].sub_topic_1_id,
            topic_2_id: idFormData.data.data[0].topic_2_id,
            sub_topic_2_id: idFormData.data.data[0].sub_topic_2_id,
            difficulty_level: idFormData.data.data[0].difficulty_level,
            answer_input_type_id: idFormData.data.data[0].answer_input_type_id,
            response_type_id: idFormData.data.data[0].response_type_id,
            evaluation_type_id: idFormData.data.data[0].evaluation_type_id,
            access_level_id: idFormData.data.data[0].access_level_id,
            taxonomy_id: idFormData.data.data[0].taxonomy_id,
            objective_id: idFormData.data.data[0].objective_id,
            max_marks: idFormData.data.data[0].max_marks,
            time_required: idFormData.data.data[0].time_required,
            medium_id: idFormData.data.data[0].medium_id,
            short_description: idFormData.data.data[0].short_description,
            description: idFormData.data.data[0].description,
            hint: idFormData.data.data[0].hint,
            solution_text: idFormData.data.data[0].solution_text,
            is_enabled: idFormData.data.data[0].is_enabled,
            answer_json: idFormData.data.data[0].answer_json,
            solution_file: idFormData.data.data[0].solution_file,
            image_path: previousImageLink,
            added_by_user_id: idFormData.data.data[0].added_by_user_id,
            question_reuse_id:
              questionReuseId === undefined ||
              questionReuseId === null ||
              questionReuseId === 0
                ? idFormData.data.data[0].question_reuse_id
                : questionReuseId,
          });
          setIsCurated(idFormData.data.data[0].is_curated);
          setIsAlreadyCurated(idFormData.data.data[0].is_curated);
          curatorId = idFormData.data.data[0].curated_by_user_id;
          curatorName = idFormData.data.data[0].curated_by_user_name;
          setPrintURL(idFormData.data.data[0].copiable_data_file_path);
          if (
            idFormData.data.data[0].solution_file &&
            idFormData.data.data[0].solution_file !== ""
          ) {
            solutionFileChoose = true;
          }
          console.log("role", role);
          console.log("formdata", formData);
          console.log("questionFIleID", questionFileId);
          if (role === "Curator") {
            setIsCurated(idFormData.data.data[0].is_curated);
            curatorName = idFormData.data.data[0].curated_by_user_name;
            curatorId = idFormData.data.data[0].curated_by_user_id;
            // toast.info("This is Curated by " + curatorName);
          }
          board_id = +idFormData.data.data[0].board_id || -1;
          course_id = +idFormData.data.data[0].course_id || -1;
          subject_id = +idFormData.data.data[0].subject_id || -1;
          sub_subject_id = +idFormData.data.data[0].sub_subject_id || -1;
          topic_1_id = +idFormData.data.data[0].topic_1_id || -1;
          topic_2_id = +idFormData.data.data[0].topic_2_id || -1;
          taxonomy_id = +idFormData.data.data[0].taxonomy_id || -1;
          questionReuseId =
            questionReuseId === undefined ||
            questionReuseId === null ||
            questionReuseId === 0
              ? idFormData.data.data[0].question_reuse_id
              : questionReuseId;

          if (
            parseInt(idFormData.data.data[0].response_type_id) ===
            matchingNumber
          ) {
            var leng = idFormData.data.data[0].answer_json.length;
            setMatchingCount(leng);
            setResponseMatchingData(idFormData.data.data[0].answer_json);
            tempAnswerJson = JSON.stringify(
              idFormData.data.data[0].answer_json
            );
            setFormData((prevState) => ({
              ...prevState,
              answer_json: tempAnswerJson,
            }));
          }
          if (
            parseInt(idFormData.data.data[0].response_type_id) === MAQNumber
          ) {
            tempArray = idFormData.data.data[0].answer_json;
            console.log("tempArray", tempArray);
            MAQArray = [];
            MAQArray = tempArray.map((num) => num.option_text);
            console.log(MAQArray);
            tempAnswerJson = JSON.stringify(
              idFormData.data.data[0].answer_json
            );
            setFormData((prevState) => ({
              ...prevState,
              answer_json: tempAnswerJson,
            }));
          }
          if (
            parseInt(idFormData.data.data[0].response_type_id) === MCQNumber
          ) {
            tempArray = idFormData.data.data[0].answer_json;
            console.log("tempArray", tempArray);
            MCQCrtArray = [0, 0, 0, 0, 0, 0];
            MCQNrArray = [0, 0, 0, 0, 0, 0];
            MCQCrtArray = tempArray.map((num) =>
              num.is_correct_option === true ? num.option_text : 0
            );
            MCQNrArray = tempArray.map((num) =>
              num.is_near_correct_option === true ? num.option_text : 0
            );
            console.log(MCQCrtArray, MCQNrArray);
            tempAnswerJson = JSON.stringify(
              idFormData.data.data[0].answer_json
            );
            setFormData((prevState) => ({
              ...prevState,
              answer_json: tempAnswerJson,
            }));
          }
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }

    if (previousPath === "viewQuestion") {
      setViewQuestion(true);
      setBacklog(false);
      setPrvLink(previousImageLink);
      if (questionReuseId && questionReuseId !== "") {
        setFormData((prevState) => {
          return { ...prevState, question_reuse_id: questionReuseId };
        });
      }
      question_id = +questionIdData;
      console.log("question_id", question_id);
      fetchFormData();
      fetchResults();
    }

    if (previousPath === "backlog") {
      setBacklog(true);
      setViewQuestion(false);
      setPrvLink(previousImageLink);
      fetchMetaData();
      setIsAlreadyCurated(false);
    }
    // eslint-disable-next-line
  }, []);

  const fetchOtherResults = async (e, selLabel) => {
    if (selLabel === "question_type_id") {
      setStartUpLoad(true);
      try {
        const getBoard = await boardList();
        setState((prevState) => ({
          ...prevState,
          boards: getBoard?.data?.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err?.response?.data) {
          console.log(err.response.data.message, "error while fetching data");
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "board_id") {
      setStartUpLoad(true);
      board_id = +e.target.value || -1;
      console.log(board_id);
      try {
        const getCourse = await fetchCourse(institution_id, board_id);
        setState((prevState) => ({
          ...prevState,
          courses: getCourse.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, "error while fetching data");
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "course_id") {
      setStartUpLoad(true);
      course_id = +e.target.value || -1;
      try {
        const getSubject = await fetchSubject(
          institution_id,
          board_id,
          course_id
        );
        setState((prevState) => ({
          ...prevState,
          subjects: getSubject.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, "error while fetching data");
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "subject_id") {
      setStartUpLoad(true);
      subject_id = +e.target.value || -1;
      try {
        const getSubSubject = await fetchSubSubject(
          institution_id,
          board_id,
          course_id,
          subject_id
        );
        const getMedium = await fetchMediumSub(subject_id);
        console.log(getMedium.data.data[0].medium_id);
        var med = getMedium.data.data[0].medium_id
          ? getMedium.data.data[0].medium_id
          : -1;
        console.log(med);
        setState((prevState) => ({
          ...prevState,
          subSubjects: getSubSubject.data.data,
        }));
        setFormData((prevState) => ({
          ...prevState,
          medium_id: med,
        }));
        setStartUpLoad(false);
      } catch (err) {
        setStartUpLoad(false);
        if (err.response && err.response.data) {
          console.log(err.response.data.message, "error while fetching data");
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
      }
    }
    if (selLabel === "sub_subject_id") {
      setStartUpLoad(true);
      sub_subject_id = +e.target.value || -1;
      try {
        const getTopic = await fetchTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id
        );
        setState((prevState) => ({
          ...prevState,
          topics: getTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, "error while fetching data");
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "topic_1_id") {
      setStartUpLoad(true);
      topic_1_id = +e.target.value || -1;
      try {
        const getSubTopic = await fetchSubTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id,
          topic_1_id
        );
        setState((prevState) => ({
          ...prevState,
          subTopics: getSubTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, "error while fetching data");
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "topic_2_id") {
      setStartUpLoad(true);
      topic_2_id = +e.target.value || -1;
      try {
        const getSubTopic = await Axios.get(
          `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_2_id}/sub_topics`,
          Auth
        );
        setState((prevState) => ({
          ...prevState,
          subTopics2: getSubTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, "error while fetching data");
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "taxonomy_id") {
      setStartUpLoad(true);
      taxonomy_id = +e.target.value;
      try {
        const getObjectives = await fetchObjective(taxonomy_id);
        setState((prevState) => ({
          ...prevState,
          objectives: getObjectives.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, "error while fetching data");
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
  };

  function handleChange(e, selLabel) {
    console.log("selLabel", selLabel);
    if (selLabel !== "is_enabled" && selLabel !== "is_curated") {
      const valueSele = e.target.value;
      fetchOtherResults(e, selLabel);
      setFormData({ ...formData, [selLabel]: valueSele });
    }
    if (selLabel === "is_enabled") {
      const valueSele = e.target.checked;
      setFormData({ ...formData, [selLabel]: valueSele });
    }
    if (selLabel === "response_type_id") {
      const valueSele = parseInt(e.target.value);
      fetchOtherResults(e, selLabel);
      setFormData({ ...formData, [selLabel]: valueSele });
    }
    if (selLabel === "is_curated") {
      const valueSele = e.target.checked;
      setIsCurated(valueSele);
      curatorName = localStorage.getItem("userName");
      curatorId = localStorage.getItem("userId");
    }
  }

  function handleRejectChange(e) {
    var rejectReasonValue = e.target.value;
    console.log(rejectReasonValue);
    setRejectReason(rejectReasonValue);
  }

  async function handleReject() {
    if (rejectReason === "") {
      toast.error("Reject reason must be specified");
    } else {
      setStartUpLoad(true);
      console.log(rejectReason);
      var rejectData = { is_rejected: true, reject_reason: rejectReason };
      try {
        var rejectQue = await rejectQuestion(question_id, rejectData);
        console.log(rejectQue.data.data);
        toast.success("Reject question successful");
        MCQCrtArray = [];
        MCQNrArray = [];
        MAQArray = [];
        window.localStorage.setItem("previousPath", "addDetails");
        setTimeout(() => history.push("/admin/view-questions"), 1500);
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
  }

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  console.log(
    configuration.digitalOceanSpacesRewrite +
      prvLink.slice(configuration.digitalOceanSpaces.length),
    prvLink,
    "prvLink"
  );

  return (
    <div>
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      {isAlreadyCurated && curatorId /*&&  +curatorId !== +userId */ ? (
        <div className="curatedWarning">
          <p className="curatedWarningP">
            This question is already curated by {curatorName}
          </p>
        </div>
      ) : null}
      <form>
        <div>
          <div className="queContainer">
            <div>
              <h3 className="queH3">
                {" "}
                <ArrowForwardIosIcon className="queH3Arrow" />
                Question Entry
              </h3>
            </div>
            <div className="queSubContainer">
              {backlog ? (
                <div>
                  <h4 className="queH4">Questions File</h4>
                  <div className="uploadedImageDisplay">
                    <div className="queContant">
                      {prvLink.endsWith(".json") ? (
                        <Player
                          autoplay
                          loop
                          src={prvLink}
                          style={{ width: "100%", height: "480px" }}
                        />
                      ) : (
                        <img
                          src={
                            REWRITE_URLS === "1"
                              ? configuration.digitalOceanSpacesRewrite +
                                prvLink.slice(
                                  configuration.digitalOceanSpaces.length
                                )
                              : prvLink
                          }
                          alt="Uploaded"
                          className="uploadedImage"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div></div>
                </div>
              )}
              {viewQuestion === true ? (
                <div>
                  <h4 className="queH4">Questions File</h4>
                  <div className="uploadedImageDisplay">
                    <div className="queContant">
                      {console.log(
                        configuration.digitalOceanSpacesRewrite +
                          prvLink.slice(
                            configuration.digitalOceanSpaces.length
                          ),
                        prvLink,
                        "prvLink"
                      )}
                      {prvLink.endsWith(".json") ? (
                        <Player
                          autoplay
                          loop
                          src={prvLink}
                          style={{ width: "100%", height: "480px" }}
                        />
                      ) : (
                        <img
                          src={
                            REWRITE_URLS === "1"
                              ? configuration.digitalOceanSpacesRewrite +
                                prvLink.slice(
                                  configuration.digitalOceanSpaces.length
                                )
                              : prvLink
                          }
                          alt="Uploaded"
                          className="uploadedImage"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="moreDropdown">
                <div className="dropdownDiv">
                  <label className="questionCopyHead">
                    {`Question Printable Data(qp)`}
                  </label>
                  <input
                    type="file"
                    name="question_file_path"
                    id="question_file_path"
                    className="fileChooseAddDetails"
                    onChange={(e) => handleChangeFile(e)}
                    accept=".doc, .docx"
                  ></input>
                  <button
                    type="button"
                    className="btnFilesUploadAddDetails"
                    onClick={(e) => copyFileUpload(e)}
                  >
                    Upload
                  </button>
                </div>
                {copyUploadLoading ? (
                  <div>
                    <ReactLoading
                      type="spinningBubbles"
                      color="#68dff0"
                      className="fileCopyUploadedSpin"
                    />
                    <h5 className="fileCopyUploadedText">Uploding</h5>
                  </div>
                ) : null}
                {copyUpload ? (
                  <p className="fileCopyUploaded">File has been Uploaded</p>
                ) : null}
              </div>
              {printURL && (
                <div
                  key={Math.random()} // This is used to re-render when the file changes
                  className="docxFileDiv"
                >
                  <FileViewer fileType={docFile} filePath={printURL} />
                </div>
              )}

              <div className="moreDropdown">
                <div className="dropdownDiv">
                  <label className="questionCopyHead">Reference</label>
                  <textarea
                    className="questionCopyTextarea"
                    onChange={(e) => handleChange(e, "reference")}
                    value={formData.reference}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="compulsoryContainer">
              <h4 className="compulsoryHeading">Hierarchy Details</h4>
              <Dropdown
                label="Questions Type"
                options={state.questionsType}
                displayName="display_name"
                name="question_type_id"
                idName="id"
                onChange={handleChange}
                value={formData.question_type_id}
                req={true}
                notFill={notFilled}
              >
                {" "}
              </Dropdown>
              <Dropdown
                label="Board"
                options={state.boards}
                displayName="board_display_name"
                name="board_id"
                idName="board_id"
                onChange={handleChange}
                value={formData.board_id}
                req={true}
                notFill={notFilled}
              >
                {" "}
              </Dropdown>
              <Dropdown
                label="Course"
                options={state.courses}
                displayName="display_name"
                name="course_id"
                idName="course_id"
                onChange={handleChange}
                value={formData.course_id}
                req={true}
                notFill={notFilled}
              >
                {" "}
              </Dropdown>
              <Dropdown
                label="Subject"
                options={state.subjects}
                displayName="display_name"
                name="subject_id"
                idName="subject_id"
                onChange={handleChange}
                value={formData.subject_id}
                req={true}
                notFill={notFilled}
              >
                {" "}
              </Dropdown>
              <Dropdown
                label="Sub-Subject"
                options={state.subSubjects}
                displayName="display_name"
                name="sub_subject_id"
                idName="sub_subject_id"
                onChange={handleChange}
                value={formData.sub_subject_id}
                req={true}
                notFill={notFilled}
                displayEngName={"display_name_eng"}
              >
                {" "}
              </Dropdown>
              <Dropdown
                label="Topic1"
                options={state.topics}
                name="topic_1_id"
                onChange={handleChange}
                idName="topic_id"
                displayName="display_name"
                value={formData.topic_1_id}
                req={true}
                notFill={notFilled}
                displayEngName={"display_name_eng"}
              />
              <Dropdown
                label="Sub-Topic1"
                options={state.subTopics}
                name="sub_topic_1_id"
                onChange={handleChange}
                idName="sub_topic_id"
                displayName="display_name"
                value={formData.sub_topic_1_id}
                req={true}
                notFill={notFilled}
                displayEngName={"display_name_eng"}
              />
              <Dropdown
                label="Topic2"
                options={state.topics}
                name="topic_2_id"
                onChange={handleChange}
                idName="topic_id"
                displayName="display_name"
                value={formData.topic_2_id}
                req={false}
                displayEngName={"display_name_eng"}
              />
              <Dropdown
                label="Subtopic2"
                options={state.subTopics2}
                name="sub_topic_2_id"
                onChange={handleChange}
                idName="sub_topic_id"
                displayName="display_name"
                value={formData.sub_topic_2_id}
                req={false}
                displayEngName={"display_name_eng"}
              />
              <h4 className="compulsoryHeading">Response Details</h4>
              <Dropdown
                label="Answer Input"
                options={state.answerInput}
                name="answer_input_type_id"
                onChange={handleChange}
                idName="id"
                displayName="display_name"
                value={formData.answer_input_type_id}
                req={true}
                notFill={notFilled}
              />
              <Dropdown
                label="Response Type"
                options={
                  +formData.answer_input_type_id === 1
                    ? state.responseType.slice(0, 3)
                    : state.responseType
                }
                name="response_type_id"
                onChange={handleChange}
                idName="id"
                displayName="display_name"
                value={formData.response_type_id}
                req={true}
                notFill={notFilled}
              />
              {parseInt(formData.response_type_id) === MCQNumber ? (
                <div className="compulsoryContainer">
                  <h4 className="optionsH4">Options</h4>
                  <div className="shortDescription">
                    <p>(Multiple correct answers allowed)</p>
                    {Array.apply(null, { length: numOptionValue }).map(
                      (e, keyNumOptionValue) => (
                        <Options
                          key={keyNumOptionValue}
                          alphabet={alphabet[keyNumOptionValue]}
                          onChange={handleChangeOptionMCQ}
                          arrayCrt={MCQCrtArray}
                          arrayNr={MCQNrArray}
                        />
                      )
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === yesOrNoNumber ? (
                <OptionsRight
                  onChange={handleChangeOptionYesNo}
                  optionOne="Yes"
                  optionTwo="No"
                  value={formData.answer_json.correct_answer}
                />
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === zeroNumber ? (
                <div className="compulsoryContainer">
                  <div className="directAnswerSingleInput">
                    <label className="searchQuestionLabel">Answer</label>
                    <input
                      className="searchQuestionSelect"
                      type="number"
                      min="0"
                      max="10"
                      onChange={(e) => handleChangeResponse(e)}
                      value={formData.answer_json.correct_answer}
                      onWheel={numberInputOnWheelPreventChange}
                    ></input>
                  </div>
                </div>
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === MAQNumber ? (
                <div className="compulsoryContainerMAQ">
                  <label className="searchQuestionLabelMAQ">Options</label>
                  {Array.apply(null, { length: numOptionValue }).map(
                    (e, keyMAQCount) => (
                      <MAQ
                        key={keyMAQCount}
                        alphabet={alphabet[keyMAQCount]}
                        onChange={handleChangeOption}
                        name="MAQ"
                        array={MAQArray}
                      />
                    )
                  )}
                </div>
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === matchingNumber ? (
                <div className="compulsoryContainer">
                  <label className="searchQuestionLabelMat">Matching</label>
                  {Array.apply(null, { length: matchingCount }).map(
                    (e, keyMatchingCount) => (
                      <Matching
                        key={keyMatchingCount}
                        number={number[keyMatchingCount]}
                        alphabet={alphabet[keyMatchingCount]}
                        onChange={handleChangeOption}
                        name="Matching"
                        onClick={(e) =>
                          handleRemove(e, keyMatchingCount, "Matching")
                        }
                        value={
                          responseMatchingData[keyMatchingCount]
                            ? responseMatchingData[keyMatchingCount]
                                .right_hand_option
                            : undefined
                        }
                      />
                    )
                  )}
                  <div className="btnDivMatching">
                    <button onClick={incrementMatching} className="btnResponse">
                      +
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {parseInt(formData.response_type_id) === oneWordNumber ? (
                <DirectAnswer
                  label="One Word Answer"
                  name="responseOneWordAnswer"
                  onChange={handleChangeResponse}
                  value={formData.answer_json.correct_answer}
                />
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === FITBNumber ? (
                <DirectAnswer
                  label="FITB"
                  name="responseFITB"
                  onChange={handleChangeResponse}
                  value={formData.answer_json.correct_answer}
                />
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === textNumber ? (
                <div>
                  <div className="compulsoryContainer">
                    <div className="directAnswerSingleInput">
                      <label className="searchQuestionLabel">Text</label>
                      <textarea
                        name="responseText"
                        className="searchQuestionSelectText"
                        onChange={(e) => handleChangeResponse(e)}
                        value={formData.answer_json.correct_answer}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === voiceNumber ? (
                <div className="compulsoryContainer">
                  <div className="directAnswerSingleInput">
                    <label className="searchQuestionLabel">Voice</label>
                    <input
                      className="fileChooseRespone"
                      type="file"
                      accept=".mp3"
                      onChange={(e) => handleResponceFile(e, "Voice")}
                    ></input>
                    <button
                      type="button"
                      className="btnFilesUpload"
                      onClick={(e) => responseUploadFile(e, "Voice")}
                    >
                      Upload
                    </button>
                  </div>
                  {resUploadLoading ? (
                    <div>
                      <ReactLoading
                        type="spinningBubbles"
                        color="#68dff0"
                        className="fileResUploadedSpin"
                      />
                      <h5 className="fileResUploadedText">Uploding</h5>
                    </div>
                  ) : null}
                  {resVoice ? (
                    <p className="fileImageUploaded">File has been Uploaded</p>
                  ) : null}
                </div>
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === imageNumber ? (
                <div className="compulsoryContainer">
                  <div className="directAnswerSingleInput">
                    <label className="searchQuestionLabel">Image</label>
                    <input
                      className="fileChooseRespone"
                      type="file"
                      accept=".jpg, .png"
                      onChange={(e) => handleResponceFile(e, "Image")}
                    ></input>
                    <button
                      type="button"
                      className="btnFilesUpload"
                      onClick={(e) => responseUploadFile(e, "Image")}
                    >
                      Upload
                    </button>
                  </div>
                  {resUploadLoading ? (
                    <div>
                      <ReactLoading
                        type="spinningBubbles"
                        color="#68dff0"
                        className="fileResUploadedSpin"
                      />
                      <h5 className="fileResUploadedText">Uploding</h5>
                    </div>
                  ) : null}
                  {resImgae ? (
                    <p className="fileImageUploaded">File has been Uploaded</p>
                  ) : null}
                </div>
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === videoNumber ? (
                <div className="compulsoryContainer">
                  <div className="directAnswerSingleInput">
                    <label className="searchQuestionLabel">Video</label>
                    <input
                      className="fileChooseRespone"
                      type="file"
                      accept=".mp4"
                      onChange={(e) => handleResponceFile(e, "Video")}
                    ></input>
                    <button
                      type="button"
                      className="btnFilesUpload"
                      onClick={(e) => responseUploadFile(e, "Video")}
                    >
                      Upload
                    </button>
                  </div>
                  {resUploadLoading ? (
                    <div>
                      <ReactLoading
                        type="spinningBubbles"
                        color="#68dff0"
                        className="fileResUploadedSpin"
                      />
                      <h5 className="fileResUploadedText">Uploding</h5>
                    </div>
                  ) : null}
                  {resVideo ? (
                    <p className="fileImageUploaded">File has been Uploaded</p>
                  ) : null}
                </div>
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === fileNumber ? (
                <div className="compulsoryContainer">
                  <div className="directAnswerSingleInput">
                    <label className="searchQuestionLabel">File</label>
                    <input
                      className="fileChooseRespone"
                      type="file"
                      accept=".pdf, .doc, .docx"
                      onChange={(e) => handleResponceFile(e, "Files")}
                    ></input>
                    <button
                      type="button"
                      className="btnFilesUpload"
                      onClick={(e) => responseUploadFile(e, "Files")}
                    >
                      Upload
                    </button>
                  </div>
                  {resUploadLoading ? (
                    <div>
                      <ReactLoading
                        type="spinningBubbles"
                        color="#68dff0"
                        className="fileResUploadedSpin"
                      />
                      <h5 className="fileResUploadedText">Uploding</h5>
                    </div>
                  ) : null}
                  {resFile ? (
                    <p className="fileImageUploaded">File has been Uploaded</p>
                  ) : null}
                </div>
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === 13 ? (
                <OptionsRight
                  onChange={handleChangeOptionYesNo}
                  optionOne="True"
                  optionTwo="False"
                />
              ) : (
                ""
              )}
              {parseInt(formData.response_type_id) === 14 ? (
                <div>
                  <div className="compulsoryContainer">
                    <div className="directAnswerSingleInput">
                      <label className="searchQuestionLabel">
                        Short Answer
                      </label>
                      <textarea
                        name="responseText"
                        className="searchQuestionSelectText"
                        onChange={(e) => handleChangeResponse(e)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="compulsoryDropdown">
                <Dropdown
                  label="Evaluation"
                  options={state.evaluation}
                  displayName="display_name"
                  name="evaluation_type_id"
                  idName="id"
                  onChange={handleChange}
                  value={formData.evaluation_type_id}
                  req={true}
                  notFill={notFilled}
                />
              </div>
              <div className="moreDropdown">
                <Dropdown
                  label="Access Level"
                  options={state.accessLevel}
                  displayName="display_name"
                  name="access_level_id"
                  idName="id"
                  onChange={handleChange}
                  value={formData.access_level_id}
                  req={true}
                  notFill={notFilled}
                />
              </div>
              <div>
                <h4 className="compulsoryHeading">Answer Details</h4>
                <Dropdown
                  label="Taxonomy"
                  options={state.taxonomies}
                  displayName="display_name"
                  name="taxonomy_id"
                  idName="id"
                  onChange={handleChange}
                  value={formData.taxonomy_id}
                  req={true}
                  notFill={notFilled}
                />
                <Dropdown
                  label="Objective"
                  options={state.objectives}
                  displayName="objective_code"
                  name="objective_id"
                  idName="objective_id"
                  onChange={handleChange}
                  value={formData.objective_id}
                  req={true}
                  notFill={notFilled}
                />
                <Dropdown
                  label="Difficulty Level"
                  options={difficultyLevel}
                  name="difficulty_level"
                  onChange={handleChange}
                  displayName=""
                  value={formData.difficulty_level}
                  req={true}
                  notFill={notFilled}
                />
                <div className="moreDropdown">
                  <div className="dropdownDiv">
                    <label
                      className={
                        (formData.max_marks === 0 ||
                          formData.max_marks === "") &&
                        notFilled === true
                          ? "dropdownLabelRed"
                          : "dropdownLabel"
                      }
                    >
                      Max Marks
                    </label>
                    <input
                      className="addInputSmall"
                      min="0"
                      onChange={(e) => handleChange(e, "max_marks")}
                      type="number"
                      required={true}
                      value={formData.max_marks}
                      onWheel={numberInputOnWheelPreventChange}
                    ></input>
                  </div>
                </div>
                <div className="moreDropdown">
                  <div className="dropdownDiv">
                    <label
                      className={
                        (formData.time_required === 0 ||
                          formData.time_required === "") &&
                        notFilled === true
                          ? "dropdownLabelRed"
                          : "dropdownLabel"
                      }
                    >
                      Time required(Sec)
                    </label>
                    <input
                      className="addInputSmall"
                      min="10"
                      step="5"
                      onChange={(e) => handleChange(e, "time_required")}
                      type="number"
                      required={true}
                      value={formData.time_required}
                      onWheel={numberInputOnWheelPreventChange}
                    ></input>
                  </div>
                </div>
              </div>
              <Dropdown
                label="Medium"
                options={state.mediums}
                displayName="medium_name"
                name="medium_id"
                idName="medium_id"
                onChange={handleChange}
                value={formData.medium_id}
                req={true}
                notFill={notFilled}
              />
              {/* <div className="moreDropdown">
                <div className="dropdownDiv">
                  <label className="dropdownLabel">Enable</label>
                  <input
                    className="addCheckbox"
                    onChange={(e) => handleChange(e, "is_enabled")}
                    type="checkbox"
                    checked={formData.is_enabled}
                  ></input>
                </div>
              </div> */}
              <div className="shortDescription">
                <label
                  className={
                    formData.short_description === "" && notFilled === true
                      ? "addLabelRed"
                      : "addLabel"
                  }
                >
                  Short Description (max 50 character)
                </label>
                <input
                  placeholder="Can add multi-linguistic text by copy paste"
                  className="addInput"
                  maxLength="50"
                  onChange={(e) => handleChange(e, "short_description")}
                  required={true}
                  value={formData.short_description}
                ></input>
                <label className="addLabelDescripton">Description</label>
                <textarea
                  className="addInput"
                  onChange={(e) => handleChange(e, "description")}
                  value={formData.description}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div></div>

        <div className="compulsoryContainer">
          <h4 className="addH4">Solution</h4>
          {/* <p className="addOpt">(optional)</p> */}
          <div className="moreDropdown">
            <div className="dropdownDiv">
              <label className="dropdownLabelHint">Hint</label>
              <input
                className="addInputSmall"
                onChange={(e) => handleChange(e, "hint")}
                value={formData.hint}
              ></input>
            </div>
          </div>
          <div className="solutionDropdownDiv">
            <label className="solutionCopyHead">Solution File</label>
            <input
              type="file"
              name="question_file_path"
              id="question_file_path"
              className="fileChooseAddDetails"
              onChange={(e) => handleSoultionFile(e)}
              accept=".pdf, .jpg, .jpeg, .png"
            ></input>
            <button
              type="button"
              className="btnFilesUploadAddDetails"
              onClick={(e) => solutionFileUpload(e)}
            >
              Upload
            </button>
          </div>
          {solUploadLoading ? (
            <div>
              <ReactLoading
                type="spinningBubbles"
                color="#68dff0"
                className="fileSolUploadedSpin"
              />
              <h5 className="fileSolUploadedText">Uploding</h5>
            </div>
          ) : null}
          {solFileUpload ? (
            <p className="fileSolUploaded">File has been Uploaded</p>
          ) : null}
          <div className="shortDescription">
            <h4 className="addH4">Solution Text</h4>
            <textarea
              placeholder="Can add multi-linguistic text by copy paste"
              className="addInput"
              onChange={(e) => handleChange(e, "solution_text")}
              value={formData.solution_text}
            ></textarea>
          </div>
        </div>

        {curator && previousPath === "viewQuestion" && (
          <div className="compulsoryContainer">
            <h4 className="addH4">Reject</h4>
            <div className="moreDropdown">
              <div className="shortDescription">
                <h4 className="addH4">Reject Reason</h4>
                <textarea
                  placeholder="Can add multi-linguistic text by copy paste"
                  className="addInput"
                  onChange={(e) => handleRejectChange(e)}
                  value={rejectReason}
                ></textarea>
              </div>
            </div>
            <button className="addReject" type="button" onClick={handleReject}>
              Reject
            </button>
          </div>
        )}
        <div
          className="compulsoryContainerCheckboxSubmit"
          // style={{ border: "1px solid red" }}
        >
          {curator && (
            <div className="addDetailsCuratedDiv">
              <input
                type="checkbox"
                value={isCurated}
                checked={isCurated}
                disabled={
                  curatorName && curatorName !== "" && isCurated ? true : false
                }
                onChange={(e) => handleChange(e, "is_curated")}
              />{" "}
              {isCurated && curatorId ? (
                <p className="addDetailsCuratedP">Curated by {curatorName}</p>
              ) : (
                <p className="addDetailsCuratedP">Curate</p>
              )}
            </div>
          )}
          <button
            type="submit"
            className="addSubmit"
            onClick={(e) => handleSubmit(e)}
            disabled={isAlreadyCurated}
          >
            Submit
          </button>
          {submitLoading ? (
            <div className="AddDetailsLoadingDiv">
              <ReactLoading
                type="spinningBubbles"
                color="#68dff0"
                className="reactLoadingIcon"
              />
              <h5 className="loadingText">Loading</h5>
            </div>
          ) : null}
          {submited ? (
            <div className="AddDetailsLoadingDiv">
              <h5 className="loadingTextData">Question is Submited</h5>
            </div>
          ) : null}
          {submitError ? (
            <div className="addDetailsLoadingDivErr">
              <h5 className="loadingTextDataErr">{errorQueSub}</h5>
            </div>
          ) : null}
        </div>
      </form>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AddDetails;
