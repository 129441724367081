import { toast } from "react-toastify";
import https from "../init/https";


//QB-REPORT-01-01: Question Bank Report -- Topic View Count
export async function topicReportCount(linkFinal) {
    const data = await https.get(`/reports/topic_report/question_count?${linkFinal}`);
    return data;
}

//QB-REPORT-01: Question Bank Report -- Topic View
export async function topicReport(linkFinal) {
    const data = await https.get(`/reports/topic_report?${linkFinal}`);
    return data;
}

//QB-REPORT-02-01: Question Bank Report -- Sub Topic View Count
export async function subTopicReportCount(linkFinal) {
    const data = await https.get(`/reports/sub_topic_report/question_count?${linkFinal}`);
    return data;
}

//QB-REPORT-02: Question Bank Report -- Sub Topic View
export async function subTopicReport(linkFinal) {
    const data = await https.get(`/reports/sub_topic_report?${linkFinal}`);
    return data;
}

//QB-REPORT-03-01: Question Bank Report -- QSet View Count
export async function qSetReportCount(linkFinal) {
    const data = await https.get(`/reports/qset_report/qset_count?${linkFinal}`);
    return data;
}

//QB-REPORT-03: Question Bank Report -- QSet View
export async function qSetReport(linkFinal) {
    const data = await https.get(`/reports/qset_report?${linkFinal}`);
    return data;
}

// QB-REPORT-01-A: Get Excel download of Topic Report
export const fetchExcelData = async (link) => {
    // console.log("link", link)
    try {
        const response = await https.get(`/reports/topic_report_xlsx?${link}`, {
            responseType: "blob"
        })
        // console.log("response", response)
        return response
    } catch (err) {
        console.log("err", err)
        throw err
    }
}

// QB-REPORT-02-A: Get Excel download of Sub Topic Report
export const fetchSubTopicExcelData = async (link) => {
    // console.log("link", link)
    try {
        const response = await https.get(`/reports/sub_topic_report_xlsx?${link}`, {
            responseType: "blob"
        })
        // console.log("response", response)
        return response
    } catch (err) {
        console.log("err", err)
        throw err
    }
}

// QB-REPORT-03-A: Get Excel download of QSet Report
export const fetchQSetReport = async (link) => {
    // console.log("link", link)
    try {
        const response = await https.get(`/reports/qset_report_xlsx?${link}`, {
            responseType: "blob"
        })
        // console.log("response", response)
        return response
    } catch (err) {
        console.log("err", err)
        throw err
    }
}